import React from "react";
import Footer from "../components/Footer";
import MainMenu from "../components/MainMenu";

export default function Imprint() {
  return (
    <div className="bg-gradient-to-r from-backgroundGradientStart to-backgroundGradientEnd">
      <MainMenu />
      <div className="bg-white">
        <div className="mx-auto max-w-7xl py-16 px-4 sm:py-24 sm:px-6 flex-row space-y-8 lg:justify-between lg:px-8">
          <div className="max-w-xl">
            <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl lg:text-6xl">
              Imprint
            </h2>
          </div>
          <p className="text-md">
            Butleroy GmbH
            <br />
            Industriezeile 35
            <br />
            4020 Linz
            <br />
            Austria
          </p>
          <p>
            <b>E-Mail:</b> hello&lt;at&gt;nftclaimer.xyz
          </p>
          <p className="leading-8">
            <b>Registration number:</b> 464025s
            <br />
            <b>Commercial Court of Registration:</b> Landesgericht Linz
            <br />
            <b>Chamber of Commerce:</b> WKO Ober&ouml;sterreich
            <br />
            <b>Tax ID:</b> ATU71740645
            <br />
            <b>Headquarters:</b> 4020 Linz
            <br />
            <b>Chief Executive Officers:</b> Philipp Baldauf, Gregor Pichler,
            Philipp Jahoda, Simon Kapl
            <br />
            <b>Authority according to ECG:</b> Magistrat Linz
            <br />
          </p>
        </div>
        <Footer />
      </div>
    </div>
  );
}
